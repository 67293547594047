import React, {useState} from 'react'
import './Main.css'
import axios from 'axios'
import AllServices from '../Services/AllServices'
import Loader from '../Services/LoadCircle/LoadCircle'
import Footer from '../Footer/Footer'

function Main() {

    const api_url = "https://usereye.azurewebsites.net/api/HttpTrigger1?url="//"https://usereye.azurewebsites.net/api/HttpTrigger1?"
    const [showServices, setServices] = useState(false);
    const [hideInput, setHideInput] = useState(false);
    const [result_data, setResultData] = useState({});
    const [start, setStart] = useState(false);

    function call_all_services(url) {
        axios.post(api_url+url)
        .then(function (response) {
            console.log(response);
            setServices(true);
            setResultData(response.data);
            setHideInput(false);
        })
        .catch(function (error) {
            console.log(error);
            setHideInput(false);
        });
    }


    function submitSearch(event) {
        if (event.key === 'Enter') {
            setStart(true);
            // Hier können Sie den Code ausführen, den Sie beim Drücken von Enter ausführen möchten.
            let input_field = document.getElementById("search__field");
            let url = input_field.value.trim();
            if (url !== "") {
                setHideInput(true);
                setServices(false);
                call_all_services(url);
            }
            else{
                alert("Bitte geben Sie eine URL ein!");
            }
        }
    };


    return (
        <>
            <div class="search__container">
                <h1 class="head_title">Website Check</h1>
                <p class="search__title">
                    Traust du der Website? Finde es heraus!
                </p>
                <input id="search__field" class="search__input" type="text" placeholder="https://www.leading.de" onKeyDown={submitSearch} disabled={hideInput}/>
            </div>

            <div class="credits__container">
                <p class="credits__text">Geben Sie bitte in das Suchfeld die URL ein</p>
            </div>
            {start ? (showServices ? 
            <AllServices data={result_data}/>: 
            <div class="loader_wrapper"><Loader/></div>)
            : <div></div>}
            <Footer/>
        </>
    )
}

export default Main