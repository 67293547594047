import React from 'react';
import './Legal.css';
import Footer from '../Footer/Footer';

class Impressum extends React.Component {
    render() {
        return (
            <div class="legal_wrapper">
                <main className="i-p i flex-center wrap">
                    <h1>Impressum</h1>
                    <section className="text-content t-i">
                        <p>Angaben gemäß § 5 TMG</p>
                        <p>
                            <strong>Betreiber der Website</strong>
                            <br />
                            Max Muster
                            <br />
                            Musterweg
                            <br />
                            12345 Musterstadt
                            <br />
                        </p>
                        <p>
                            <strong>Vertreten durch: </strong>
                            <br />
                            Max Muster
                            <br />
                        </p>
                        <p>
                            <strong>Kontakt:</strong>
                            <br />
                            Telefon: 01234-789456
                            <br />
                            Fax: 1234-56789
                            <br />
                            E-Mail: <a href='mailto:register@usereye.de'>register@usereye.de</a>
                        </p>
                        <p>
                            <strong>Registereintrag: </strong>
                            <br />
                            Eintragung im Registergericht: Musterstadt
                            <br />
                            Registernummer: 12345
                            <br />
                        </p>
                        <p>
                            <strong>Umsatzsteuer-ID: </strong>
                            <br />
                            Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: Musterustid.
                            <br />
                            <strong>Wirtschafts-ID: </strong>
                            <br />
                            Musterwirtschaftsid
                        </p>
                    </section>
                </main>
                <Footer />
            </div>

        );
    }
}

export default Impressum;
