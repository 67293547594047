import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Main from './Main/Main';
import Impressum from './Legal/Imprint';
import Datenschutz from './Legal/Privacy';

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
      </Routes>
    </BrowserRouter>

  )
}

export default App;