import React from 'react';
import Footer from '../Footer/Footer';
import './Legal.css';

class Datenschutz extends React.Component {
    render() {
        return (
            <>
                <div class="legal_wrapper">
                    <main className="i-p flex-center wrap">
                        <h1>Datenschutz</h1>
                        <section className="text-content">
                            <p>
                                <br /><strong>Eu- Streitschlichtung</strong>
                                <br />
                                Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir 
                                Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
                                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen 
                                Kommission unter <a href='http://ec.europa.eu/odr?tid=321203021'>http://ec.europa.eu/odr?tid=321203021</a>zu richten. 
                                Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
                                <br />
                                Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an 
                                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                            </p>
                            <p>
                                <br /><strong>Haftung für Inhalte dieser Website</strong>
                                <br />
                                Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. 
                                Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen,
                                nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite
                                übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht 
                                verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine 
                                rechtswidrige Tätigkeit hinweisen. Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung 
                                von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer
                                Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt. Sollten Ihnen problematische oder rechtswidrige 
                                Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können.
                                Sie finden die Kontaktdaten im Impressum.
                            </p>
                            <p>
                                <strong>Haftung für Links auf dieser Website</strong>
                                <br />
                                Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. 
                                Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, 
                                uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
                                Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
                            </p>
                            <p>
                                <strong>Datenschutz</strong>
                                <br />
                                Der Betreiber verpflichtet sich selbstverständlich zu einem umfassenden Schutz Ihrer persönlichen Daten nach den Bestimmungen des Bundesdatenschutzgesetzes. 
                                Jede Information, die Sie uns geben, wird persönlich und vertraulich behandelt. Wir übermitteln Ihre Daten unter keinen Umständen an Dritte. 
                                Der Betreiberverpflichtet sich weiterhin Ihre Daten ausnahmslos intern zu verwenden. Sie können jederzeit Auskunft über die bei beim Betreiber 
                                gespeicherten Daten erhalten, sowie Ihre Daten beim Betreiber berichtigen, sperren oder löschen lassen.
                            </p>
                            <p>
                                <strong>BootstrapCDN Datenschutzerklärung</strong>
                                <br />
                                Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website) auf allen Geräten schnell und sicher ausliefern zu können, 
                                nutzen wir das Content Delivery Network (CDN) BootstrapCDN des amerikanischen Software-Unternehmens StackPath, LLC 2012 McKinney Ave.
                                Suite 1100, Dallas, TX 75201, USA.Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander verbunden sind. 
                                Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen schnell ausgeliefert werden.
                                BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden. Lädt nun Ihr Browser eine Datei 
                                vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung zum Bootstrap-CDN-Server an das Unternehmen StockPath übermittelt.
                            </p>
                        </section>
                    </main>
                </div>
                <Footer />
            </>

        
        );
    }
}

export default Datenschutz;
