import React from 'react'

function AllServices(props) {

  const data = props.data? props.data : {};

  const domainage = data.domainage;
  const imprint = data.imprint;
  const marketplace = data.marketplace;
  const privacy = data.privacy;
  const ssl_cert = data.ssl_cert;
  const status_code = data.status_code;
  const emails = data.email;
  const phones = data.phone;
  
  return (
    <>
    {data && 
        <div class="service_calls">
            <div class="custom_row row">
                <div class="wrapper_services col-lg-4">
                {<p>{status_code}</p> }
                <h3>Status</h3>
                </div>
                <div class="wrapper_services col-lg-4">
                    <p>{ssl_cert ? "SSL Zertifikat vorhanden": "SSL Zertifikat nicht vorhanden"}</p>
                    <h3>SSL</h3>
                </div>
                <div class="wrapper_services col-lg-4">
                    {<p>{domainage}</p>}
                    <h3>Domain Alter</h3>
                </div>
            </div>
            <div class="custom_row row">
                <div class="wrapper_services col-lg-4">
                    <p>{imprint}</p>
                    <h3>Impressum</h3>
                </div>
                <div class="wrapper_services col-lg-4">
                    <p>{privacy}</p>
                    <h3>Datenschutzrichtlinien</h3>
                </div>
                <div class="wrapper_services col-lg-4">
                    <p>{emails}</p>
                    <h3>Emails</h3>
                </div>
            </div>
            <div class="custom_row row">
                <div class="wrapper_services col-lg-4">
                    <p>{phones}</p>
                    <h3>Telefonnummern</h3>
                </div>
                <div class="wrapper_services col-lg-4">
                    <p>{marketplace}</p>
                    <h3>Domain im Handel</h3>
                </div>
            </div>
        </div>
    }
    </>

  )
}

export default AllServices