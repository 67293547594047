import React from 'react';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
    <div class="footer">
      <footer className="unset">
        <section className="flex-center">
          <p className="owner">&copy; 2021 Usereye</p>
          <nav>
            <ul>
              <li>
                <a href="http://18.156.3.190/docs">API</a>
              </li>
              <li>
                <a href="/impressum">Impressum</a>
              </li>
              <li>
                <a href="/datenschutz">Datenschutz</a>
              </li>
            </ul>
          </nav>
        </section>
      </footer>
    </div>

    );
  }
}

export default Footer;